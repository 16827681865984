<template>
  <div id="app">
    <HeaderPart />
    <div class="socialMediaBox" v-if="screenWidth > screenBreakLG">
      <img src="@/assets/icons/instagram.svg" alt="Instagram Icon" />
    </div>
    <main role="main">
      <transition name="fade" mode="in-out">
        <router-view :key="$route.fullPath" />
      </transition>
    </main>
    <FooterPart />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { useWindowScroll } from "@vueuse/core";
export default {
  name: "App",
  computed: {
    ...mapState([
      "cmsUrl",
      "screenWidth",
      "screenBreakLG",
      "activeMenuPoint",
      "navOpen",
      "token",
      "pages",
    ]),
    page: {
      get() {
        return (
          this.pages.find(
            (page) => page.attributes.pagename === this.$route.name
          )?.attributes || {}
        );
      },
      deep: true,
    },
  },
  components: {
    HeaderPart: () => import("@/components/templateParts/HeaderPart.vue"),
    FooterPart: () => import("@/components/templateParts/FooterPart.vue"),
  },

  methods: {
    handleResize() {
      this.$store.dispatch("setScreenWidth");
    },
    async fetchInitalData() {
      // Productions
      let stateName = "productions";
      let apiUrl = `${process.env.VUE_APP_API_URL}/api/${stateName}?populate=*&sort[0]=premiere:desc`;
      await this.$store.dispatch("fetchDataFromApi", {
        apiUrl,
        stateName,
      });

      // History
      stateName = "histories";
      apiUrl = `${process.env.VUE_APP_API_URL}/api/${stateName}?populate=*&sort[0]=year:desc`;
      await this.$store.dispatch("fetchDataFromApi", {
        apiUrl,
        stateName,
      });

      // News
      stateName = "articles";
      apiUrl = `${process.env.VUE_APP_API_URL}/api/${stateName}?populate=*&sort[0]=releasedate:desc`;
      await this.$store.dispatch("fetchDataFromApi", {
        apiUrl,
        stateName,
      });

      // faqs
      stateName = "faqs";
      apiUrl = `${process.env.VUE_APP_API_URL}/api/${stateName}?populate=*&sort[0]=question`;
      await this.$store.dispatch("fetchDataFromApi", {
        apiUrl,
        stateName,
      });

      // privacy
      stateName = "privacy";
      apiUrl = `${process.env.VUE_APP_API_URL}/api/${stateName}?populate=*`;
      await this.$store.dispatch("fetchDataFromApi", {
        apiUrl,
        stateName,
      });
    },
  },
  created() {
    this.fetchInitalData();
    this.$store.dispatch("initializeLanguage");
  },
  mounted() {
    // Set ScreenWidth
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    // Update ScrollPosition
    const { y } = useWindowScroll();
    const handleScroll = () => {
      this.$store.commit("SET_SCROLL_POSITION", y.value);
    };
    window.addEventListener("scroll", handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="sass">
@import '@/assets/sass/main.sass'
.socialMediaBox
  position: fixed
  top: 30vh
  left: 0
  width: 4rem
  height: 3.5rem
  background-color: $gold
  z-index: 5
  transition: transform .5s
  img
    width: 50%
    margin: 15% 25%
  &:hover
    transform: scale(1.1)
</style>
