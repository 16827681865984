import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    production: "production",
    productions: "productions",
    coproduction: "Co-production",
    coproductions: "co-productions",
    cinema: "cinema",
    all: "all",
    author: "Author",
    news: "news",
    article: "article",
    history: "history",
    contact: "contact",
    imprint: "imprint",
    privacypolicy: "privacy policy",
    toMovie: "To the movie",
    readmore: "More",
    munich: "Munich",
    archive: "Archive",
    close: "close",
    premiere: "premiere",
    originalTitle: "original title",
    autographRequests: "Autograph requests",
    pressRequests: "PR- / event- and interview requests",
    stamping: "Please enclose a sufficiently stamped and addressed envelope.",
    autographInfo:
      "Please understand that personal dedications and/or greetings for special occasions (wedding, birthday, etc.) cannot be processed due to time constraints.",
    operator: "Operator",
    phone: "Phone",
    email: "Email",
    director: "Managing Director",
    comreg: "Commercial Register",
    vat: "VAT Identification Number",
    responsible: "Responsible",
    register: "Commercial Register",
    k_work: "Projectmanagement, text & PR",
    s_work: "Usability, UX-Design & Development",
    realisation: "Realisation & Customer Care digital",
    },
  de: {
    production: "Produktion",
    productions: "Produktionen",
    coproduction: "Co-Produktion",
    coproductions: "Co-Produktionen",
    cinema: "Kino",
    all: "Alle",
    author: "Autor",
    news: "Aktuelles",
    article: "Artikel",
    history: "Firmengeschichte",
    contact: "Kontakt",
    imprint: "Impressum",
    privacypolicy: "Datenschutz",
    toMovie: "Zum Film",
    readmore: "weiterlesen",
    munich: "München",
    archive: "Archiv",
    close: "Schliessen",
    premiere: "Kinostart",
    originalTitle: "Originaltitel",
    autographRequests: "Autogrammanfragen",
    pressRequests: "Presse- / Event- und Interviewanfragen",
    stamping:
      "Bitte einen ausreichend frankierten und adressierten Rückumschlag beilegen.",
    autographInfo:
      "Bitte haben Sie Verständnis dafür, dass persönliche Widmungen und/oder Grüße für besondere Anlässe (Hochzeit, Geburtstag, etc.) aus zeitlichen Gründen nicht bearbeitet werden können.",
    operator: "Betreiber",
    phone: "Telefon",
    email: "E-Mail",
    director: "Geschäftsführer",
    comreg: "Amtsgericht",
    vat: "Umsatzsteueridentifikation",
    responsible: "Verantwortlich",
    register: "Amtsgericht",
    k_work: "Projektmanagement, Text & PR",
    s_work: "Usability, UX-Design & Entwicklung",
    realisation: "Realisation & Betreuung digital",
    },
};

const i18n = new VueI18n({
  locale: "de", // set locale
  fallbackLocale: "de",
  messages, // set locale messages
});

export default i18n;
